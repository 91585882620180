import _ from 'lodash';
import React from 'react';

import './App.scss';
import data from './questions.json';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: _.mapKeys(data, 'Id'),
      selectedExam: 1,
      count: 0,
      resolved: 0,
      err: 0,
      questions: {},
      currentQuestion: '',
      showAnswer: false,
      view: 'home'
    };
  }

  componentDidMount() {
    console.log('data', this.state.data);
    this.findMissingAnswers();
  }

  findMissingAnswers() {
    let { data } = this.state;
    let missing = [];
    let misQ = [];
    Object.keys(data).forEach(examKey => {
      let exam = data[examKey];
      exam.Parts.forEach(part => {
        part.Questions.forEach(question => {
          if (!question.Answer) {
            missing.push(`${exam.Id}-${part.Id}-${question.Id}`);
          }
          if (!question.Question) {
            misQ.push(`${exam.Id}-${part.Id}-${question.Id}`);
          }
        })
      })
    });
    console.log('missing answers:', missing);
    console.log('missing questions:', misQ);
  }

  startTest() {
    let parts = this.state.data[this.state.selectedExam].Parts;
    let questions = parts.reduce((r, p) => {
      let q = p.Questions.map(o => ({ ...o, partId: p.Id, partName: p.Name }))
      r = [...r, ...q];
      return r;
    }, []);
    let count = questions.length;
    questions = _.mapKeys(questions, o => `${o.partId}-${o.Id}`);
    let currentQuestion = this.choseQuestion(questions);
    this.setState({ view: 'test', questions, currentQuestion, showAnswer: false, count, resolved: 0, err: 0 });
  }

  stopTest() {
    this.setState({ view: 'home' });
  }

  showAnswer() {
    this.setState({ showAnswer: true });
  }

  nextQuestion(result) {
    let { questions, currentQuestion, resolved, err, count } = this.state;

    if (result) {
      questions = { ...questions };
      delete questions[currentQuestion];
      resolved = resolved + 1;
      currentQuestion = this.choseQuestion(questions);
      this.setState({ questions, currentQuestion, resolved, showAnswer: false });
    } else {
      err = err + 1;
      currentQuestion = this.choseQuestion(questions);
      this.setState({ currentQuestion, err, showAnswer: false });
    }
  }

  choseQuestion(questions) {
    if (!questions) {
      return '';
    }

    let keys = Object.keys(questions);
    if (!keys.length) {
      return '';
    }

    var i = Math.floor(Math.random() * keys.length);
    // console.log('random', i, keys.length);
    return keys[i];
  }

  render() {
    let { resolved, count, err, questions, currentQuestion, selectedExam, view, showAnswer } = this.state;
    let showStart = false;
    let showTest = false;
    switch (view) {
      case 'test':
        showTest = true;
        break;
      default:
        showStart = true;
        break;
    }
    let testFinished = !Object.keys(questions).length;
    // console.log('questions', { questions, currentQuestion, count, questionsCount: Object.keys(questions).length });

    return (
      <div className="App">
        <header className="App-header">
          {showTest &&
            <div className="test-container">
              <div className="test-head">
                <div className="resolved-container">
                  <div className="resolved-label">Vyřešeno:</div>
                  <div className="resolved-value">{resolved} / {count}</div>
                </div>
                <div className="err-container">
                  <div className="err-label">Chybných:</div>
                  <div className="err-value">{err}</div>
                </div>
              </div>
              {!testFinished &&
                <div className="test-body">
                  <div className="test-question-container">
                    <div className="test-question">{selectedExam}-{currentQuestion}: {questions[currentQuestion].Question}</div>
                  </div>
                  <div className="test-answer-container">
                    {showAnswer &&
                      <div className="test-answer">Odpověď: {questions[currentQuestion].Answer}</div>
                    }
                  </div>
                  <div className="test-btns-container">
                    <div className="feedback">
                      {showAnswer &&
                        <>
                          <div
                            className="btn btn-light"
                            onClick={() => this.nextQuestion(true)}
                          >Věděl jsem</div>
                          <div
                            className="btn btn-light"
                            onClick={() => this.nextQuestion(false)}
                          >Nevěděl jsem</div>
                        </>
                      }
                      {!showAnswer &&
                        <div
                          className="btn btn-light"
                          onClick={() => this.showAnswer()}
                        >Zobrazit odpověď</div>
                      }
                    </div>
                  </div>
                </div>
              }
              {testFinished &&
                <div className="simple-container">
                  <div>Všechny otázky byly zodpovězeny. Spusťte test znovu.</div>
                </div>
              }
              <div className="buttons">
                <div
                  className="btn btn-light"
                  onClick={() => this.stopTest()}
                >Vypnout tréning</div>
              </div>
            </div>
          }
          {showStart &&
            <div className="start-container">
              <div className="select-container">
                <select
                  className="form-control"
                  value={selectedExam}
                  onChange={e => this.setState({ selectedExam: e.target.value })}
                >
                  {data.map(o => (
                    <option key={o.Id} value={o.Id}>{o.Name}</option>
                  ))}
                </select>
              </div>
              <div className="buttons">
                <div
                  className="btn btn-light"
                  onClick={() => this.startTest()}
                >Spustit tréning</div>
              </div>
            </div>
          }
          <div className="email">Kdyby byl nějaký problém, tak napiš sem:  <a href="mailto:michal.penkala@gmail.com">michal.penkala@gmail.com</a></div>
        </header>
      </div>
    );
  }
}

export default App;
